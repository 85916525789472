@import "../../utils/styles/index"

.select-modal
  &-portal-fonts
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 9
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(255,255,255,.4)
    .select-modal
      margin: 0 48px
      position: static
      +if-sm
        margin: 0
        .select-modal__right-col
          position: relative
          border-top: 1px solid #707070
          margin-top: 53px
        .select-modal__left-col
          padding-bottom: 100px !important
          box-sizing: border-box
.font-card
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  padding: 20px 26px
  &__container
    width: 100%
  &__list
    margin: 0
    padding: 0
    list-style: none
  &__item
    text-align: left
    font-size: 20px
    line-height: 25px
