@import "utils/styles/index"

.design
  &__text-group-switcher-row
    width: 100%
    display: flex
    flex-wrap: wrap
    .select
      width: 100%
  &__text-outside-row
    position: relative
    z-index: 1
    margin-left: -20px
    width: 100%
    height: 35px
    display: flex
    align-items: center
    margin-bottom: 11px
    +if-sm
      margin-left: -16px
      width: 93%
    &--text-group, &--image-group
      height: initial
      max-width: 100%
      margin-bottom: 22px
      .select
        margin: 4px
      .design__text-outside-row-wrap
        display: flex
        flex-wrap: wrap
        box-sizing: border-box
        .design__label:first-child
          margin-left: 9px
      .design__text-outside-row-wrap-controls
        display: flex
        flex-wrap: wrap
        box-sizing: border-box
        .design__label:first-child
          margin-left: 5px
          &:nth-of-type(7)
            margin-left: 5px
        .design__many-label:first-child
          margin-left: 12px
    &-title
      min-width: 170px
      font-size: 12px
      font-weight: 300
      white-space: pre-wrap
      +if-xs
        min-width: 132px
    &-wrap
      display: flex
      background-color: #fff
      box-shadow: 0 3px 6px #00000029
      border-radius: 7px
      margin-left: 20px
      +if-md
        border-radius: 23px
      +if-xs
        border-radius: 0
        border-top-right-radius: 23px
        border-bottom-right-radius: 23px
      .design__label
        margin: 6px 0 6px 14px
        &:first-child
          margin-left: 50px
        &:last-child
          margin-right: 9px
    &-wrap-controls
      display: flex
      background-color: #fff
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05)
      border-radius: 30px
      margin-left: 20px
      +if-md
        border-radius: 23px
      +if-xs
        border-radius: 0
        border-top-right-radius: 23px
        border-bottom-right-radius: 23px
      .design__label
        margin: 4px 0 4px 12px
        &:first-child
          margin-left: 50px
        &:last-child
          margin-right: 5px
  &__text-outside-subrow
    display: flex
    margin-bottom: 10px
  &__sublabel
    &:hover
      opacity: .8
    &-input
      display: none
      &:checked ~ .design__sublabel-pseudo
        background-color: $colorMain
    &-pseudo
      min-width: 28px
      height: 28px
      display: flex
      justify-content: center
      align-items: center
      font-size: 12px
      font-weight: 500
      line-height: initial
      border-radius: 14px
      background-color: #d1d1d1
      color: #fff
      cursor: pointer
      padding: 0 20px
      margin-right: 9px
      &.active
        background-color: $colorMain
      span
        margin-top: 2px
  &__select
    min-width: 55px
    max-width: 171px
    height: 32px
    display: flex
    align-items: center
    box-sizing: border-box
    font-size: 14px
    font-weight: 500
    border-top-right-radius: 7px
    border-bottom-right-radius: 7px
    padding: 0 16px
    background-color: $colorMain
    color: #fff
    white-space: nowrap
    +if-xs
      padding: 0 13px
    .dropdown-icon
      margin-bottom: -3px
      margin-left: 5px
    .dropdown-icon-img
      margin-bottom: 9px
      margin-left: 5px
  &__tab__title-text
    display: flex
    margin-left: 15px
    margin-right: auto
    padding-right: 16px
    padding-top: 2px
    font-size: 14px
    line-height: 20px
    font-weight: 500
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  .long
    min-width: 70px
    max-width: 110px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  &__text-outside-row-wrap-specific
    min-width: 100%
    display: flex
    align-items: center
    justify-content: flex-end
    box-sizing: border-box
    background-color: #fff
    box-shadow: none
    &:first-child
      margin-top: 42px
  &__tab-controls
    width: 100%
    display: flex
    flex-wrap: wrap
  &__audio
    &-delete
      @include cleanButton
      margin-right: 91px
      display: flex
      padding: 0
      cursor: pointer
      +if-xs
        margin-right: 24px
      img
        width: 16px
        height: 16px
        margin-left: 12px
        margin-top: 6px
    &-name
      margin: 0
      font-size: 14px
      font-weight: 400
      white-space: nowrap
      padding-top: 4px
      .text-cut
        max-width: 210px
      +if-xs
        margin-left: 16px
    &-input
      display: none
    &-label
      &-pseudo
        width: 31px
        height: 30px
        display: flex
        justify-content: center
        align-items: center
        border-radius: 100%
        background-color: $colorMain
        cursor: pointer
        margin-right: 5px
        &:hover
          opacity: .86
  &__text-outside-row-wrap--audio
    min-width: 330px
    width: 100%
    box-sizing: border-box
    padding: 5px 7px 5px 7px
    border: 1px solid #707070
    +if-xs
      margin-left: 0
      max-width: 135%
      min-width: 214px
    .design__tab__title-text
      display: flex
      margin: 0

  .preloader
    background-color: #E3F3FF
  .image-group
    position: absolute
    top: -1000vh
    max-width: 319px
    max-height: 400px
    +if-xs
      max-width: 280px
    &--visible
      top: 0
      max-width: 319px
      max-height: 400px
      +if-xs
        max-width: 280px
.design__text-outside-row-title
  width: 100%
.design-text__text-group-label
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  .dropdown-icon
    margin-left: 5px
    margin-bottom: 1px
.design-text__switcher-icons
  margin: 10px 10px 0 0
.design-text__select-container
  display: flex
  justify-content: space-between
  align-items: center
  min-width: 46%
  max-width: 46%
  margin-right: 10px
  margin-bottom: 10px
