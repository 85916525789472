@import "utils/styles/index"

.overview
  .account-information__buttons-row
    +if-md
      order: 2
  &__left-col
    width: 519px
    +if-lgx
      width: 400px
    +if-md
      width: 100%
      order: 1
  &__right-col
    width: calc(100% - 519px)
    +if-lgx
      width: calc(100% - 400px)
    +if-md
      width: 100%
      order: 0
      display: flex
      padding-bottom: 16px
      justify-content: center
  &__logo
    position: relative
    &-image
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover
    &-delete
      @include cleanButton
      position: absolute
      top: 0
      right: 0
      z-index: 3
      margin-top: 26px
      width: 20px
      height: 20px
      display: flex
      padding: 0
      background-color: #fff
      border-radius: 100%
      cursor: pointer
      img
        width: 20px
        height: 20px
    &-input
      display: none
    &-label
      position: relative
      width: 218px
      height: 218px
      display: flex
      justify-content: center
      align-items: center
      margin-left: auto
      border: 1px solid #707070
      border-radius: 100%
      cursor: pointer
      font-size: 14px
      font-weight: 300
      overflow: hidden
      +if-default
        width: 200px
        height: 200px
        margin-left: auto
      +if-lg
        width: 160px
        height: 160px
        margin-left: auto
      img ~ span
        display: none
      span
        position: absolute
        z-index: 2
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        background-color: rgba(255,255,255,.3)
  &__form
    width: 100%
    display: flex
    flex-wrap: wrap
    +if-h-full-hd
      min-height: 100%
  .field-group
    margin-bottom: 27px
    max-width: 100%
    +if-lg
      margin-bottom: 16px
    &:last-child
      margin-bottom: 0
    &__input
      height: 40px
      +placeholder
        +if-lgx
          font-size: 16px
    label
      text-align: left
      line-height: 13px
      margin-bottom: 13px
