@mixin cleanButton()
  background-color: transparent
  border: 0
@mixin cleanLink()
  text-decoration: none
  cursor: pointer
@mixin cleanList()
  list-style: none
  padding: 0
  margin: 0
@mixin cleanField
  border: 0
  background-color: transparent

@keyframes animationShownUp
  0%
    opacity: 0
  100%
    opacity: 1
@keyframes animationShownDown
  0%
    opacity: 1
  25%
    opacity: 0.75
  50%
    opacity: 0.5
  75%
    opacity: 0.25
  100%
    display: none
