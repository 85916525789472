@font-face
  font-family: Helvetica Neue
  font-weight: 900
  src: url('../../assets/fonts/Helvetica Neue/HelveticaNeue-Heavy.ttf')
@font-face
  font-family: Helvetica Neue
  font-weight: 500
  src: url('../../assets/fonts/Helvetica Neue/HelveticaNeue-Medium.ttf')
@font-face
  font-family: Helvetica Neue
  font-weight: 400
  src: url('../../assets/fonts/Helvetica Neue/HelveticaNeue-Roman.ttf')
@font-face
  font-family: Helvetica Neue
  font-weight: 300
  src: url('../../assets/fonts/Helvetica Neue/HelveticaNeue-Light.ttf')
