@import "../../utils/styles/index"

.select-themes-list
  height: 500px
  width: 400px
  box-sizing: border-box
  border: 1px solid #707070
  +if-sm
    max-height: 60vh
    min-height: 300px
.theme-card
  width: 100%
  display: flex
  justify-content: space-between
  border-bottom: 1px solid #707070
  align-items: center
  box-sizing: border-box
  +if-sm
    height: 90px
  img
    width: 100px
    height: 100px
    object-fit: cover
    flex-shrink: 0
    +if-sm
      width: 92px
      height: 90px
  &__info
    display: flex
    align-items: center
    margin: 0
    padding: 0
    list-style: none
    +if-sm
      font-size: 16px
  &__theme-card
    height: 100px
    text-align: left
  .checked-icon
    margin-right: 24px
  .theme__name
    width: 150px
    margin-left: 16px
    margin-right: 16px
    font-size: 16px
    line-height: 18px
    font-weight: 900
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    +if-sm
      font-size: 16px
      margin: 0 16px
    +if-xs
      font-size: 14px
      line-height: 18px
