@import "utils/styles/index"

.modal-page
  position: fixed
  top: 0
  left: 0
  z-index: 10
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(255,255,255,.3)
  animation: .5s animationShownUp
.account-information
  margin: 40px 115px
  width: 1200px
  max-height: 700px
  height: 100%
  display: flex
  flex-wrap: wrap
  align-content: flex-start
  box-shadow: 0 4px 15px -2px #707070
  background-color: #fff
  border-radius: 8px
  +if-lg
    height: calc(100vh - 64px)
    margin: 32px
  +if-sm
    margin: 0
    height: calc(100vh - 51px)
    margin-top: 51px
    box-sizing: border-box
    border: 0
    box-shadow: none
  &__left-col
    width: 271px
    height: 100%
    box-sizing: border-box
    border-right: 1px solid $colorMain
    background-color: $colorMain
    border-top-left-radius: 8px
    border-bottom-left-radius: 8px
    +if-md
      width: 200px
    +if-sm
      display: none
  &__right-col
    width: calc(100% - 271px)
    +if-md
      width: calc(100% - 200px)
    +if-sm
      width: 100%
      height: 100%
      box-sizing: border-box
      padding-bottom: 92px
    &-wrap
      width: 100%
      min-height: 100%
      display: flex
      flex-wrap: wrap
      box-sizing: border-box
      padding: 28px 34px 24px 49px
      +if-lgx
        padding: 16px 24px
      +if-sm
        padding: 16px
  &__title
    width: 100%
    height: 69px
    display: flex
    align-items: center
    margin: 0
    box-sizing: border-box
    padding: 0 19px
    font-weight: 700
    font-size: 20px
    line-height: 24px
    color: #fff
  &__nav-list
    @include cleanList
    a
      @include cleanLink
      position: relative
      width: 100%
      height: 68px
      display: flex
      align-items: center
      box-sizing: border-box
      padding: 0 18px
      font-weight: 700
      font-size: 16px
      color: #fff
      transition-duration: .25s
      &:hover
        background-color: #deedf9
      &.active
        background-color: #fff
        color: #000
        &::after
          content: ''
          position: absolute
          top: 0
          left: 100%
          width: 2px
          height: 100%
          background-color: #fff
  &__buttons-row
    width: 100%
    display: flex
    justify-content: flex-end
    padding-top: 30px
    margin-top: auto
    .button
      min-width: 102px
      max-height: 39px
      margin-left: 19px
      line-height: 32px
      font-size: 14px
      border-radius: 8px
    .cancel
      border-radius: 8px
      background-color: #F03D3E
      border: 1px solid #F03D3E
    .save
      border-radius: 8px
      background-color: #00D7B4
      border: 1px solid #00D7B4
  &__changelog
    display: flex
    width: 100%
    padding: 100px 18px 0 18px
    &-link
      text-align: center
      font-size: 18px
      color: #fff
      text-decoration: none
      &:hover
        opacity: 0.8
