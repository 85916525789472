@import "utils/styles/index"

=cardStyles
  margin: 20px 0 34px
  width: 100%
  max-width: 355px
  border-radius: 23px
  box-sizing: border-box
  padding: 21px 0 26px
  background-color: #F2F2F2
  +if-lg
    margin-bottom: 0
  ul
    width: 100%
    margin: 0
    display: flex
    flex-wrap: wrap
    padding: 0
    list-style: none
    color: #AFAFAF
    font-size: 14px
    font-weight: 500
.invoices-list
  +cardStyles
  margin: 20px 0 20px
  height: 132px
  ul
    li
      width: 100%
      margin-bottom: 11px
      padding: 0 27px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      span
        max-width: 100%
        display: inline-block
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
.current-payment
  +cardStyles
  margin: 20px 0 34px
  ul
    li
      width: 100%
      display: flex
      align-items: center
      padding: 0 27px
      &:first-child
        margin-bottom: 16px
      span
        width: 42%
        white-space: nowrap
        display: inline-block
        box-sizing: border-box
        padding-right: 21px
        &:last-child
          width: 58%
          padding-left: 21px
          padding-right: 0
.billing-information
  width: 100%
  &__left
    width: 448px
    +if-lg
      width: 100%
  &__right
    width: calc(100% - 448px)
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    box-sizing: border-box
    padding-left: 28px
    +if-lg
      width: 100%
      align-items: flex-start
    +if-md
      padding-left: 0
    &-col
      width: 100%
      +if-lg
        width: 50%
        +if-md
          width: 100%
  &__add-payment, &__download-invoices
    padding: 0 27px
    font-weight: 500
    font-size: 11px
    line-height: 30px
  &__add-payment
    margin-top: 26px
  &__download-invoices
    +if-lg
      margin-top: 26px
  &__form
    width: 100%
    display: flex
    flex-wrap: wrap
    min-height: 100%
  .field-group
    width: 579px
    max-width: 100%
    margin-bottom: 28px
    +if-lg
      width: 100%
      box-sizing: border-box
      padding-right: 24px
    +if-sm
      padding-right: 0
    &:last-child
      margin-bottom: 0
    +if-h-full-hd
      margin-bottom: 28px
    +if-default
      margin-bottom: 24px
    +if-lgx
      margin-bottom: 18px
    +if-lg
      margin-bottom: 16px
    +if-sm
      margin-bottom: 28px
    &__input
      height: 40px
      +placeholder
        +if-lgx
          font-size: 16px
    label
      text-align: start
      font-size: 14px
      line-height: 13px
      margin-bottom: 13px
  &__address-group
    margin-top: 42px
    +if-md
      max-width: 100%
    .field-group
      margin-bottom: 14px
      &__error
        margin-top: -2px
  .account-information__buttons-row
    padding-top: 26px
