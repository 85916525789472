@import "../../utils/styles/index"

.select-colors
  &__row
    position: relative
    width: 100%
    display: flex
    justify-content: center
    align-content: flex-start
    flex-wrap: wrap
    +if-sm
      margin-bottom: 32px
    .preloader-parent
      display: flex
  &__delete-button
    position: absolute
    bottom: 100%
    left: 100%
    margin-left: -12px
    margin-bottom: -12px
    display: none
    border: 0
    padding: 0
    border-radius: 100%
    background-color: #fff
    fill: #FF0000
    cursor: pointer
  &__help-text
    position: absolute
    top: -95%
    left: 95%
    margin-left: 50px
    width: 175px
    font-size: 10px
    line-height: 12px
    color: #898989
    +if-sm
      width: 100%
      position: static
      margin: 8px 0
      text-align: center
  &__title
    width: 100%
    display: inline-block
    margin-bottom: 7px
    font-size: 14px
    font-weight: 300
    text-align: center
    +if-sm
      line-height: initial
      br
        display: none
  &__color-box
    position: relative
    width: 34px
    height: 34px
    margin: 5px
    display: flex
    justify-content: center
    align-items: center
    box-sizing: border-box
    border: 1px solid #707070
    border-radius: 100%
    +if-sm
      width: 32px
      height: 32px
      margin: 3px
    &:hover
      .select-colors__delete-button
        display: flex
    &--add
      cursor: pointer
      border-color: $colorMain
      color: #fff
      border-radius: 10px
      &:hover
        background-color: #fafafa
  &__input
    display: none
  &__icon
    width: 20px
    line-height: 20px
    vertical-align: middle
    text-align: center
    font-size: 20px
    font-weight: 600
    color: #fff
  &__toggle
    width: 100%
    margin: 4px 0 9px
    display: flex
    align-items: center
    font-size: 14px
    font-weight: 300
    .title
      font-size: 20px
      margin-right: 20px
  &__info-text
    font-weight: normal
    font-size: 12px
    line-height: 12px
    color: #000
    opacity: 0.33
    margin-top: 15px
    margin-bottom: 44px
