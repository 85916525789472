.lazy-scroll
  &__preloader
    width: 100%
    display: flex
    justify-content: center
    padding: 4px 0
    img
      width: 60px


