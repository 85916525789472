@import "utils/styles/index"

.select-theme
  &__row
    width: 400px
    max-width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: flex-start
    +if-default
      width: 320px
  &__title
    width: 100%
    margin-bottom: 19px
    display: inline-block
    font-weight: 300
    font-size: 20px
  &__button-row
    width: 100%
    display: flex
    justify-content: flex-end
  &__list
    @include cleanList
    width: 100%
    margin-left: 29px
    +if-default
      margin-left: 0
    +if-sm
      width: 100%
    li
      width: 90%
      max-width: 100%
      height: 34px
      margin-bottom: 9px
      box-sizing: border-box
      padding: 0 16px
      border-radius: 7px
      line-height: 33px
      text-align: center
      font-weight: 900
      font-size: 14px
      background-color: $colorMain
      color: #F7F7F7
      +if-md
        width: 100%
        margin-left: 0
        font-size: 14px
      +if-sm
        width: 90%
        margin-bottom: 7px
  &__button[class]
    min-width: 98px
    line-height: 37px
    max-height: 39px
    display: inline-block
    margin-top: 19px
    margin-left: auto
    margin-right: 35px
    font-size: 14px
    border-radius: 7px
    background-color: #BD47DA
    border-color: #BD47DA
    +if-sm
      margin-right: 0
