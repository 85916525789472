@import '../../utils/styles'

.Toastify
  &__toast
    opacity: .95
    &--success
      background: $colorMain
    &--error
      background: $colorError
    &--warning
      background: $colorWarning

.notify
  &__content
    display: flex
    flex-direction: column
    justify-content: space-around
  &__title
    font-family: "Helvetica Neue", sans-serif
    font-weight: 600
    font-size: 16px
    margin-bottom: 3px
  &__text
    max-width: 280px
    font-family: "Helvetica Neue", sans-serif
    font-weight: 500
    font-size: 14px
