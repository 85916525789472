@import "utils/styles/index"

.select-font
  &__container
    width: 100%
    display: flex
    justify-content: center
  &__row
    position: relative
    width: 100%
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    min-height: 50px
    .preloader-parent
      display: flex
      width: 100%
      margin: 0 auto
  &__button
    position: relative
    width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    padding: 0
    border: 0
    border-radius: 100%
    background-color: $colorMain
    cursor: pointer
    transition-duration: .25s
    &:hover
      background-color: $colorMain
    &:active
      background-color: $colorMain
      box-shadow: inset 0 0 6px #10598c
    .svg-button
      fill: #fff
      width: 24px
  &__help-text
    position: absolute
    top: -50%
    left: 95%
    margin-left: 50px
    width: 175px
    font-size: 10px
    line-height: 14px
    color: #898989
    +if-sm
      position: static
      width: 100%
      margin: 16px 0
      text-align: center
  &__title
    width: 100%
    display: inline-block
    margin-bottom: 4px
    font-size: 14px
    line-height: 24px
    font-weight: 300
    text-align: center
    +if-sm
      br
        display: none
  &__list-selected
    @include cleanList
    margin-right: 41px
    font-size: 13px
    line-height: 15px
    opacity: .33
    +if-sm
      margin-right: 8px
    +if-xs
      font-size: 10px
    li
      text-align: left
      white-space: nowrap
  &__row-button-open
    width: 100%
    display: flex
    margin-top: 10px
  &__info-text
    font-weight: normal
    font-size: 12px
    line-height: 12px
    color: #000
    opacity: 0.33
    margin-top: 15px
