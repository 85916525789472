@import "utils/styles/index"

.frame
  background-color: #F9FAFC
  border-radius: 4px
  padding: 12px 12px 12px 12px
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.02), 0 4px 6px 1px rgba(0, 0, 0, 0.06)
  .super-lottie
    position: relative
    div
      box-sizing: border-box
      svg
        margin-bottom: -4px
    &__lottie-loader
      width: 50px
      position: absolute
      top: -10px
      right: 0
      z-index: 100
      opacity: 0
      transition: .5s opacity
      & ~ div
        resize: both
      +if-sm
        top: 0
    &__fake
      position: absolute
      width: 100%
      height: 100%
      top: 0
      left: 0
      z-index: 99
      box-sizing: border-box
      margin-top: -3px
      border-top: 1px solid #fff
      &[data-use]
          & ~ div
            opacity: 0
          & ~ .super-lottie__lottie-loader
            opacity: 1
