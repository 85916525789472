@import "utils/styles/index"

.audio-editor
  width: 100%
  margin-top: 7px
  &__duration
    &-row
      width: 100%
      justify-content: space-between
      display: flex
      margin-bottom: 19px
    &-col
      width: 33.3%
      display: flex
      align-items: flex-start
      justify-content: center
      flex-wrap: wrap
      &:first-child
        .audio-editor__duration-title
          margin-left: 25px
          text-align: left
    &-title
      width: 100%
      margin-bottom: 14px
      text-align: center
      font-size: 12px
      font-weight: 600
    &-value
      width: 100%
      height: 30px
      display: flex
      align-items: center
      justify-content: center
      font-size: 12px
      font-weight: 600
    &-input
      width: 60px
      height: 30px
      font-size: 12px
      font-weight: 600
      line-height: 30px
      border-radius: 23px
      text-align: center
      border: none
      background-color: #fff
  &__wrap-group
    width: 100%
    box-sizing: border-box
    padding: 0 11px 0 6px
    .design__text-outside-row-wrap-specific
      border-radius: 7px
      margin-bottom: 24px
  &__wrap
    position: relative
    width: 100%
    height: 38px
    display: flex
    align-items: center
    justify-content: flex-start
    margin-bottom: 24px
    box-sizing: border-box
    padding-left: 5px
    overflow: hidden
    border: 1px solid #fff
    background-color: #fff
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05)
    border-radius: 23px
    &.audio-wrap
      padding: 0 22px !important
      & > div
        position: absolute !important
        top: 0
        left: 22px
        width: calc(100% - 44px) !important
        height: 46px !important
        canvas
          width: 100% !important
          height: 100% !important
    .input-range
      position: relative
      height: 46px
      z-index: 2
    +if-xs
      max-width: 100%
      margin: 0 auto 24px
    .checkbox__pseudo
      width: 32px
      height: 32px
      background-color: #fff
    .checkbox
      margin-right: 9px
      margin-left: auto
  &__title-wrap
    font-weight: 300
    font-size: 12px
  &__buttons
    display: flex
    justify-content: center
    margin-right: 9px
    margin-left: auto
    margin-top: 20px
  &__time
    position: absolute
    top: 0
    left: 22px
    width: calc(100% - 44px)
    z-index: 3
    box-sizing: border-box
    &-line
      width: 100%
      height: 1px
      background-color: #707070
    &-pointer
      width: 40px
      height: 40px
      border: 1px solid #707070
      background-color: #5DA8DD
    .input-range
      &__label
        display: none
      &__track--background
        height: 100%
        margin-top: -23px
        background-color: transparent
        &::after
          position: absolute
          content: ''
          width: 100%
          height: 1px
          left: 0
          margin-top: -23px
          background-color: #707070
      &__track--active
        height: 46px
        background-color: #5DA8DD
        border-radius: 0
        opacity: .8
      &__slider
        width: 1px
        height: 46px
        margin-top: -46px
        margin-left: 0
        border-radius: 0
        box-sizing: border-box
        border: 0
        background-color: $colorMain
        transform: scale(1) !important
  .player__button
    .pause-icon
      display: flex
      justify-content: center
      &::after, &::before
        content: ''
        width: 6px
        height: 18px
        background-color: #fff
      &::before, &::after
        margin: 0 2px
  .audio-editor__progress-line
    position: absolute
    width: 100%
    z-index: 0
    margin-top: -40px
    .input-range
      &__slider-container
        background-color: transparent
      &__slider
        background-color: red
        width: 10px
        height: 10px
        border-radius: 100%
        margin-top: -5px
        opacity: .8
    .input-range__track--active
      background-color: transparent !important
.dropdown-icon
  margin-bottom: 3px !important
