@import "fonts"
@import "./normallize.css"
@import "./colors"
@import "./consts"

html
  font-family: "Object sans", sans-serif

body
  width: 100vw
  overflow-x: hidden

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
  background-color: transparent
  transition: background-color 5000s ease-in-out 0s !important

.font
  &--italic
    font-style: italic
  &--bold
    font-weight: 600
  &--regular
    font-weight: 400
    font-style: normal

@mixin buttonStyle($color)
  background-color: $color
  border: 1px solid $color
  &:hover
    background-color: transparent
    color: $color
    &:active
      color: $color
      box-shadow: inset 0 0 6px $color
      background-color: transparent
.button
  display: inline-block
  text-align: center
  border-radius: 8px
  font-weight: 500
  line-height: 55px
  color: #fff
  box-sizing: border-box
  font-size: 23px
  text-decoration: none
  transition-duration: .25s
  cursor: pointer
  @include buttonStyle(#05AF00)
  &--red
    @include buttonStyle(#FF0000)
  &--main
    @include buttonStyle($colorMain)
  &--black
    @include buttonStyle(#000)
  &--grey
    @include buttonStyle(#898989)
  &--light-grey
    @include buttonStyle(#D1D1D1)
  &--empty
    color: #000
    border-color: #707070
    background-color: transparent
    &:hover
      background-color: #000
      color: #fff
      border-color: #000
.radio-as-button
  input
    display: none
    &:not(:checked) ~ label
      border: 1px solid #707070
      color: #000
      background-color: transparent
.row
  width: 100%
.pre-wrap
  white-space: pre-wrap
  +if-sm
    white-space: normal
.xs-hidden
  +if-xs
    display: none !important
.mb-0[class]
  margin-bottom: 0 !important
.text
  &--center
    text-align: center
  &--left
    text-align: left
  &--right
    text-align: right
.text-normalizer
  display: inline-block
  padding-top: .2em
.text-cut
  display: inline-block
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
.react-datepicker__day--selected
  background-color: $colorMain !important

#modal-root
  position: absolute
  z-index: 11

.zapier-modal__time-picker
  .rc-time-picker-panel-inner
    width: 100%
    color: #000
    border-radius: 24px
    font-size: 14px
  .rc-time-picker-panel-input
    padding-left: 10px

