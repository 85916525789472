@import "../../../utils/styles/index"

.field-group
  position: relative
  width: 100%
  margin-bottom: 32px
  +if-sm
    margin-bottom: 16px
  &--last
    margin-bottom: 0
  &__input
    width: 100%
    height: 52px
    box-sizing: border-box
    padding: 0 20px
    border-radius: 5px
    border: 1px solid #ACB5BD
    font-weight: 400
    font-size: 18px
    color: #495057
    +if-sm
      height: 55px
    +placeholder
      color: #DDE2E5
  &__label
    width: 100%
    display: inline-block
    margin-bottom: 15px
    font-size: 12px
    font-weight: 400
    text-transform: uppercase
    letter-spacing: 0.08em
    color: #ACB5BD
  &__error
    position: absolute
    top: 100%
    left: 0
    width: 100%
    display: inline-block
    padding: 4px 0
    font-size: 12px
    color: $colorError
    &::first-letter
      text-transform: uppercase
