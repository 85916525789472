.select
  position: relative
  &__select
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    opacity: 0
    border-radius: 7px
    cursor: pointer
    option
      font-size: 14px
