@import "utils/styles/index"

.page-wrapper
  height: 100%
  width: 100%
  min-height: 100vh
  display: flex
  justify-content: center
  align-content: stretch
  align-items: center
  background-color: #F9FAFC
  &--main
    display: flex
    align-content: flex-start
    flex-wrap: wrap
    height: auto
    background-color: #F9FAFC
    +if-sm
      margin-top: 51px
      margin-bottom: 0
