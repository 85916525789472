@import "../../utils/styles/index"

.page-error
  width: 100vw
  height: 100vh
  box-sizing: border-box
  padding: 30px
  overflow-x: hidden
  +if-sm
    padding: 8px
  &__wrapper
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    box-sizing: border-box
    padding: 30px
    background-color: $colorMain
    +if-sm
      padding: 8px
  &__container
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
  &__title
    width: 100%
    margin: 10px 0
    text-align: center
    font-size: 64px
    line-height: 80px
    color: #fff
    +if-sm
      font-size: 32px
      line-height: 40px
  &__sub-title
    width: 100%
    text-align: center
    margin-bottom: 30px
    font-size: 20px
    font-weight: 300
    line-height: 34px
    opacity: .6
    color: #fff
    +if-sm
      margin-bottom: 16px
      font-size: 14px
      line-height: 1.4em
  &__go-back
    @include cleanButton
    width: 242px
    display: inline-block
    text-align: center
    line-height: 58px
    background-color: #fff
    text-decoration: none
    text-transform: uppercase
    font-weight: 300
    font-size: 14px
    letter-spacing: 2px
    color: #202020
    cursor: pointer
    transition-duration: .25s
    +if-sm
      width: initial
      line-height: 42px
      padding: 0 16px
      letter-spacing: 1px
    &:hover
      background-color: hsla(0,0%,100%,.8)
