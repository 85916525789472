@import "../../utils/styles/index"

.select-modal
  &-portal-themes
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 9
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(255,255,255,.4)
    .select-modal
      margin: 0 48px !important
      position: static
      +if-sm
        margin: 0 !important
        .select-modal__right-col
          border-top: 1px solid #707070
          margin-top: 53px
        .select-modal__left-col
          padding-bottom: 100px !important
          box-sizing: border-box
.select-modal
  position: absolute
  z-index: 3
  bottom: 100%
  width: 730px
  height: 440px
  display: flex
  border: 1px solid #707070
  background-color: #fff
  +if-md
    max-width: 100vw
    margin-left: 40px
  +if-sm
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    margin-left: 0
    flex-wrap: wrap
    overflow-y: scroll
  &__left-col
    width: 300px
    text-align: left
    box-sizing: border-box
    padding: 28px 14px 14px 40px
    +if-sm
      width: 100vw
      padding: 16px
      order: 2
      br
        display: none
  &__title
    font-size: 16px
    line-height: 24px
    font-weight: 900
  &__prev-text
    font-size: 14px
    font-weight: 300
  &__request[class]
    margin: 30px 0 110px
    min-width: 98px
    line-height: 34px
    font-size: 14px
    +if-sm
      margin: 16px 0 42px
  &__done[class]
    min-width: 116px
    line-height: 42px
    max-height: 42px
    font-size: 16px
  &__right-col
    width: calc(100% - 300px)
    height: 100%
    border-left: 1px solid #707070
    +if-sm
      width: 100vw
      border-left: 0
      order: 0
  &__font-card
    border-bottom: 1px solid #707070
.font-card
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  box-sizing: border-box
  padding: 20px 26px
  &__list
    margin: 0
    padding: 0
    list-style: none
  &__item
    text-align: left
    font-size: 18px
    line-height: 21px
  .checkbox__pseudo
    width: 34px
    height: 34px
