=if-large
  @media (max-width: 2100px)
    @content
=if-default
  @media (max-width: 1660px)
    @content
=if-lgx
  @media (max-width: 1380px)
    @content
=if-lg
  @media (max-width: 1240px)
    @content
=if-md
  @media (max-width: 980px)
    @content
=if-sm
  @media (max-width: 767px)
    @content
=if-xs
  @media (max-width: 480px)
    @content
=if-xs-x
  @media (max-width: 350px)
    @content
=if-h-full-hd
  @media (min-width: 1080px) and (max-height: 1200px)
    @content
=placeholder
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
  &:-moz-placeholder
    @content
