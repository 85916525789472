@import "utils/styles/index"

.design-presets
  position: relative
  width: 100%
  display: flex
  flex-wrap: wrap
  +if-lgx
    box-sizing: border-box
    padding-bottom: 34px
  .select-font__button
    position: static
  .select-modal
    bottom: initial
    top: 50%
    left: 50%
    margin-top: -220px
    margin-left: -365px
    &__fonts
      top: 60%
      left: 115%
  .select-font__row
    position: static
    align-content: flex-start
    +if-sm
      margin-bottom: 52px
  &__brandName
    width: 579px
    max-width: 100%
    margin-bottom: 40px
    +if-h-full-hd
      margin-bottom: 24px
    +if-lgx
      width: initial
    +if-lg
      width: 100%
    +if-md
      margin-bottom: 16px
    +if-sm
      margin-bottom: 41px
    .field-group__input
      height: 40px
      +placeholder
        +if-lgx
          font-size: 14px
    label
      text-align: left
  &__form
    width: 100%
  &__wrap
    width: 100%
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
  &__right-col
    width: calc(100% - 400px)
    padding-left: 30px
    +if-lg
      width: calc(100% - 300px)
      padding-left: 30px
    +if-md
      width: calc(100% - 235px)
      padding-left: 15px
    +if-sm
      width: 100%
  &__left-col
    width: 360px
    display: flex
    justify-content: flex-start
    align-content: flex-start
    flex-wrap: wrap
    +if-lg
      width: 340px
    +if-md
      width: 240px
    +if-sm
      width: 100%
    .select-logo__row
      justify-content: flex-start
      flex-wrap: wrap
      +if-sm
        width: 100%
        justify-content: center
        margin-bottom: 29px
      .design-presets__brand-logo
        width: 100%
        margin-bottom: 12px
        text-align: left
        font-weight: 300
        font-size: 14px
        line-height: 24px
        +if-sm
          margin-bottom: 27px
    .select-colors__row
      display: flex
      justify-content: flex-start
      +if-sm
        margin-bottom: 52px
      .preloader-parent
        width: 100%
        display: flex
        flex-wrap: wrap
      .select-colors__title
        text-align: left
    .select-font__row
      .select-font__title
        text-align: left
  .account-information__buttons-row
    +if-xs
      justify-content: center
      padding-top: 16px
  .select-colors__color-box--add
    background-color: $colorMain
  .select__label
    min-width: 40px
    max-width: 100px
    height: 32px
    margin-left: 8px
    display: flex
    align-items: center
    justify-content: center
    background-color: $colorMain
    color: #fff
    padding: 2px 20px
    font-size: 14px
    line-height: 14px
    border-radius: 7px
  &__row
    width: 100%
    margin-top: 32px
  &__double-row
    width: 100%
    display: flex
    align-items: center
    margin-top: 32px
  &__field-group
    display: flex
    align-items: center
    margin-left: 12px
    &:first-child
      margin-left: 0
  &__field
    display: flex
    justify-content: space-between
    margin-bottom: 10px
  &__title
    font-size: 14px
    font-weight: 300
    line-height: 37px
  &__customButton
    min-width: 98px
    max-height: 39px
    margin-left: 19px
    font-weight: 500
    margin-right: 35px
    line-height: 37px
    font-size: 14px
    border-radius: 7px
    background-color: $colorMain
    border: 1px solid $colorMain
    color: #fff
    +if-sm
      margin-right: 8px
