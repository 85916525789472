@import "utils/styles/index"

.select-logo
  &__row
    position: relative
    width: 100%
    margin-bottom: 50px
    display: flex
    flex-wrap: wrap
    justify-content: center
    &--has-file
      border-radius: 100%
      img
        border-radius: 100%
      .select-logo__field:hover
        &::before
          content: ''
          position: absolute
          z-index: 3
          top: 0
          left: 0
          width: 100%
          height: 100%
          background-color: rgba(255,255,255, .4)
        .select-logo
          &__title, &__icon, &__params
            position: relative
            z-index: 2
  &__logo
    position: absolute
    width: 100%
    object-fit: contain
    z-index: 2
  &__cover
    position: absolute
    width: 100%
    object-fit: contain
    z-index: 1
    background-color: white
    height: 100%
  &__help-text
    position: absolute
    top: 50%
    left: 85%
    margin-top: -20px
    margin-left: -60px
    width: 120px
    font-size: 10px
    line-height: 12px
    color: #898989
    +if-sm
      position: static
      width: 100%
      text-align: center
      margin: 16px 0
    a
      color: #1369A5
      text-decoration: none
      &:hover
        text-decoration: underline
  &__field
    position: relative
    width: 150px
    height: 150px
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-content: flex-start
    border: 1px solid #707070
    border-radius: 100%
    transition-duration: .25s
    cursor: pointer
    &:hover
      background-color: #fafafa
    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover
  &__title
    width: 100%
    text-align: center
    margin: 12px 0 15px
    font-size: 14px
    font-weight: 300
    +if-sm
      br
        display: none
  &__icon
    width: 22px
  &__params
    font-size: 7px
    line-height: 8px
    text-align: center
    color: rgba(0,0,0,.33)
  &__input
    display: none
  &__reset-button
    @include cleanButton
    position: absolute
    bottom: 100%
    left: 100%
    z-index: 3
    margin-left: -15%
    margin-bottom: -27%
    display: flex
    padding: 0
    border-radius: 100%
    background-color: #fff
    cursor: pointer
    width: 20px
    height: 20px
